
export const GET_WEBSITE_CONTENT = 'GET_WEBSITE_CONTENT';
export type GET_WEBSITE_CONTENT = typeof GET_WEBSITE_CONTENT;

export const RECEIVED_WEBSITE_ENTRIES = 'RECEIVED_WEBSITE_ENTRIES';
export type RECEIVED_WEBSITE_ENTRIES = typeof RECEIVED_WEBSITE_ENTRIES;

export const CLOSE_INTRO_MESSAGE = 'CLOSE_INTRO_MESSAGE';
export type CLOSE_INTRO_MESSAGE = typeof CLOSE_INTRO_MESSAGE;

export const SELECTED_VACATION = 'SELECTED_VACATION';
export type SELECTED_VACATION = typeof SELECTED_VACATION;

export const EMPTY_VACATION = 'EMPTY_VACATION';
export type EMPTY_VACATION = typeof EMPTY_VACATION;

export const SELECT_INITIAL_CONTACT_FORM = 'SELECT_INITIAL_CONTACT_FORM';
export type SELECT_INITIAL_CONTACT_FORM = typeof SELECT_INITIAL_CONTACT_FORM; 

export const SEND_FORM_TO_GOOGLE_SHEETS = 'SEND_FORM_TO_GOOGLE_SHEETS';
export type SendFormToGoogleSheets = typeof SEND_FORM_TO_GOOGLE_SHEETS;

export const CONTACT_SUCCEEDED = 'CONTACT_SUCCEEDED';
export type CONTACT_SUCCEEDED = typeof CONTACT_SUCCEEDED;