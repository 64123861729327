import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { CHATEAU_ROUTE_URL, VILLAS_ROUTE_URL, CONTACT_ROUTE_URL } from './Routes';
interface State {
  menuOpen: boolean;
}
interface Props {

}
export class Nav extends React.Component<Props, State> {
  state = {
    menuOpen: false
  };
  render() {
    return (
      <nav className={this.state.menuOpen ? 'menu-open' : ''}>
        <span className="brand">Goed in Frankrijk</span>
        <a onClick={() => this.onMenuChange()}><i className="material-icons mobileMenu">
          menu
                  </i></a>
        <ul>
          <NavLink to="/" exact={true} onClick={() => this.closeMenu()}>
            <li>
              Homepage
                        </li>
          </NavLink>
          <NavLink to={CHATEAU_ROUTE_URL} onClick={() => this.closeMenu()}>
            <li>
              Chateau
                      </li>
          </NavLink>
          <NavLink to={`${VILLAS_ROUTE_URL}/`} onClick={() => this.closeMenu()}>
            <li>
              Villa's
                      </li>
          </NavLink>
          {/* <li><NavLink to={OMGEVING_ROUTE_URL}>Omgeving</NavLink></li> */}
          <NavLink to={CONTACT_ROUTE_URL} onClick={() => this.closeMenu()}>
            <li>
              Contact
                      </li>
          </NavLink>
        </ul>
        <span className="hamburger" />
      </nav>
    );
  }
  closeMenu(): void {
    this.setState({menuOpen: false});
  }
  onMenuChange() {
    const open = ! this.state.menuOpen;
    this.setState(() => ({... this.state, menuOpen: open}));
  }
}