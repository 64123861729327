import { Sitemap } from './Types/index';
import { ContentfulClient } from './Contentful';
import { Footer } from './Footer/Footer';
import { Provider } from 'react-redux';
import { VillaPage } from './Pages/Villa/Villa';
import { ChateauPage } from './Pages/Chateau/Chateau';
import { ConnectedRouter } from 'react-router-redux';

import { Home } from './Pages/Home';
import * as React from 'react';
import './App.css';
import 'whatwg-fetch';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import { configureStore, history } from './Store';
import { OmgevingPage } from './Pages/Omgeving/OmgevingPage';
import { ContactPage } from './Pages/Contact/ContactPage';
import { CHATEAU_ROUTE_URL, CONTACT_ROUTE_URL, OMGEVING_ROUTE_URL, VILLAS_ROUTE_URL } from './Routes';
import { Nav } from './Nav';
interface Props {
}
interface State {
  footerSitemap: Sitemap;
}
class App extends React.Component<Props, State> {
  client = ContentfulClient;
  state = {
    footerSitemap: {
      items: [{
        title: 'Loading footer',
        items: []
      }]
    }
  };
  // constructor() {
  //   super();
  //   history.listen((location: Location) => {
  //     // tslint:disable-next-line:no-string-literal
  //     window['ga']('set', 'page', location.pathname + location.search);
  //     // tslint:disable-next-line:no-string-literal
  //     window['ga']('send', 'pageview');
  // });  
  // }

  render() {
    return (
      <div className="App">
        <Router>
          <Provider store={configureStore()}>
            <ConnectedRouter history={history}>
              <div>
                <Nav />
                <section className="main-body">
                  <Route exact={true} path="/" component={Home} />
                  <Route exact={true} path={CHATEAU_ROUTE_URL} component={ChateauPage} />
                  <Route exact={true} path={OMGEVING_ROUTE_URL} component={OmgevingPage} />
                  <Route path={`${VILLAS_ROUTE_URL}`} component={VillaPage} />
                  <Route exact={true} path={CONTACT_ROUTE_URL} component={ContactPage} />
                  <Route path={`${CHATEAU_ROUTE_URL}/:post`} component={ChateauPage} />
                  <Route path={`${OMGEVING_ROUTE_URL}/:item`} component={OmgevingPage} />
                  {!!this.state && !!this.state.footerSitemap ? <Footer /> : ''}
                </section>
              </div>
            </ConnectedRouter>
          </Provider>
        </Router>
      </div>
    );
  }
}

export default App;
